<template>
   <div class="container">
    <b-card no-body>
    <b-tabs pills card>
      <b-tab :title="status" :active="status==='Final'" v-for="(group, status) in groupedSales" :key="status">
        <b-card-text>
        <b-table style="height: 300px;" class="overflow-auto"
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="group"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ data.index+1 }}
          </a>
        </template>
        <template v-slot:cell(sale_id)="data">
          <div
            class="font-size-12">
            {{ data.item.sale_id }} ({{ data.item.customer.name }})
          </div>
        </template>
        <template v-slot:cell(grand_total)="data">
          <div
            class="font-size-12">
            KSh.{{ new Intl.NumberFormat().format(data.item.grand_total) }}
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
            <a href="#" class=" uil uil-edit text-primary" v-b-modal.modal-Add @click="edit(data.item)"></a>
            </li>
            <li class="list-inline-item">
                <a href="#" class="uil uil-trash-alt text-danger" @click="deleterec(data.index,data.item.id,data.item.sale_id)"></a>
            </li>
            <li class="list-inline-item">
                <a href="#" class=" uil-print text-secondary" @click="fetchReceiptData(data.item.id)"></a>
            </li>
        </ul>
        </template>
      </b-table>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
  <Receipt :sale="receiptData" @printReceipt="fetchReceiptData" />
</div>
</template>
<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";
import Receipt from "@/views/pages/pos/printReceipt";

export default {
  name: "Recents",
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [1, 5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
         {key: 'id', label: '', sortable: true },
         {key: 'sale_id', label: '', sortable: true },
         {key: 'grand_total', label: '', sortable: true },
         {key: 'action', label: '', sortable: true },
        ],
        recents:[],
        receiptData:null,
    };
  },
  components: {
    Receipt,
  },
  computed: {
    rows() {
      return this.recents.length;
    },
    groupedSales() {
      const grouped = {};
      this.recents.forEach(sale => {
        if (!grouped[sale.status]) {
          grouped[sale.status] = [];//init the different status groups to empty list
        }
        grouped[sale.status].push(sale);
      });
      return grouped;
  }
  },
  created(){
  },
  mounted() {
    this.fetchRecents();
    this.totalRows = this.recents.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchReceiptData(id){
      axios.get(`sale_receipt_data/?id=${id}`)
      .then((response)=>{
        this.receiptData=response.data;
        //console.log(response.data)
        this.$emit('printReceipt')
      }).catch((e)=>{
        console.log(e);
      })
    },
    fetchRecents() {
      axios
        .get(`recentSales/`)
        .then((res) => {
         this.recents=res.data;
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
            timer: 3000,
          });
        });
    },
    deleterec(index, id, code) {
      Swal.fire({
        title: "Are you sure, you want to delete sale #" + code + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("sales/" + id + "/")
            .then(() => {
              this.recents.splice(index, 1);
              Swal.fire("Deleted!", code + " has been deleted.", "success");
            })
            .catch((e) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error!",
                html: e,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
  },
};
</script>