<template>
  <div class="container">
    <b-form @submit.prevent="handleSubmit">
      <div class="row border-top border-3 border-primary rounded-2 bg-light mb-2">
        <div class="col-lg-3 p-1">
          <label>Expense Category:</label>
          <div class="mb-3">
            <multiselect
              v-model="expense.category"
              :options="expenseCategories"
              :multiple="false"
              label="name"
              track-by="id"
              placeholder="Select..."
              class=""
            ></multiselect>
          </div>
        </div>
        <div class="col-lg-3 p-1">
          <label>Reference No:</label>
          <b-form-input v-model="expense.reference_no"></b-form-input>
        </div>
        <div class="col-lg-3 p-1">
          <label>Date Added:</label>
          <b-form-datepicker v-model="expense.date_added"></b-form-datepicker>
        </div>
      </div>
      <div class="row border-top border-3 border-primary rounded-2 bg-light mb-2">
        <div class="col-lg-3 p-1">
          <label>Expense For User:</label>
          <div class="mb-3">
            <multiselect
              v-model="expense.expense_for_user"
              :options="users"
              :multiple="false"
              label="username"
              track-by="id"
              placeholder="Select..."
              class=""
            ></multiselect>
          </div>
        </div>
        <div class="col-lg-3 p-1">
          <label>Expense For Contact:</label>
          <div class="mb-3">
            <multiselect
              v-model="expense.expense_for_contact"
              :options="contacts"
              :multiple="false"
              label="fullName"
              track-by="id"
              placeholder="Select..."
              class=""
            ></multiselect>
          </div>
        </div>
        <div class="col-lg-3 p-1">
          <label>Attach Document:</label>
          <b-form-file v-model="expense.attach_document"></b-form-file>
        </div>
      </div>
      <div class="row border-top border-3 border-primary rounded-2 bg-light mb-2">
        <div class="col-lg-3 p-1">
          <label>Applicable Tax:</label>
          <div class="mb-3">
            <multiselect
              v-model="expense.applicable_tax"
              :options="taxRates"
              :multiple="false"
              label="tax_name"
              track-by="id"
              placeholder="Select..."
              class=""
            ></multiselect>
          </div>
        </div>
        <div class="col-lg-3 p-1">
          <label>Total Amount:</label>
          <b-form-input v-model="expense.total_amount" type="number"></b-form-input>
        </div>
        <div class="col-lg-3 p-1">
          <label>Expense Note:</label>
          <b-form-textarea v-model="expense.expense_note"></b-form-textarea>
        </div>
      </div>
      <div class="row border-top border-3 border-primary rounded-2 bg-light mb-2">
        <div class="col-lg-2 p-1">
          <label>Is Refund:</label>
          <b-form-checkbox v-model="expense.is_refund"></b-form-checkbox>
        </div>
        <div class="col-lg-2 p-1">
          <label>Is Recurring:</label>
          <b-form-checkbox v-model="expense.is_recurring"></b-form-checkbox>
        </div>
        <div class="col-lg-2 p-1">
            <b-form-group label="Reccurring Interval">
            <b-form-input v-model="expense.recurring_interval" type="number" min="0" class="w-50 d-inline"></b-form-input>
            <b-form-select v-model="expense.interval_type" :options="intervals" placeholder="Select..." class="w-25 d-inline p-2 mb-1" ></b-form-select>
            </b-form-group>
        </div>
        <div class="col-lg-2 p-1">
          <label>Repetitions:</label>
          <b-form-input v-model="expense.repetitions" type="number"></b-form-input>
        </div>
      </div>
    </b-form>
    <div class="row">
      <b-button class="btn btn-secondary w-25" @click="addExpense()">Save</b-button>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  data() {
    return {
      expenseCategories: [], // Array to store expense categories
      users: [], // Array to store users
      contacts: [], // Array to store contacts
      taxRates: [], // Array to store tax rates
      intervals:["Days","Weeks","Months","Years"],
      expense: {
        register: null,
        business_location: null,
        category: null,
        reference_no: "",
        date_added: moment(new Date()).format("YYYY-DD-MM"),
        expense_for_user: null,
        expense_for_contact: null,
        attach_document: null,
        applicable_tax: null,
        total_amount: null,
        expense_note: "",
        is_refund: false,
        is_recurring: false,
        recurring_interval: 0,
        interval_type:"Days",
        repetitions: null,
      },
      // Add more data properties as needed
    };
  },
  components: {
    Multiselect,
  },
  created() {
    // Fetch expense categories, users, contacts, and tax rates
    this.fetchExpenseCategories();
    this.fetchUsers();
    this.fetchContacts();
    this.fetchTaxRates();
  },
  methods: {
    // Method to fetch expense categories
    fetchExpenseCategories() {
      axios.get("expensecategories").then((response) => {
        this.expenseCategories = response.data['results'];
      });
    },
    // Method to fetch users
    fetchUsers() {
      axios.get("listusers/").then((response) => {
        this.users = response.data;
      });
    },
    // Method to fetch contacts
    fetchContacts() {
      axios.get("contacts/").then((response) => {
        let data = response.data['results'];
        this.contacts=data.map(contact => ({
            ...contact,
            fullName: `${contact.user.first_name} ${contact.user.last_name}`
            }));
      });
    },
    // Method to fetch tax rates
    fetchTaxRates() {
      axios.get("tax-rates").then((response) => {
        this.taxRates = response.data['results'];
      });
    },
    // Method to add a new expense
    addExpense() {
      // Submit the expense data to the backend API
      axios
        .post("expenses", this.expense)
        .then((response) => {
          // Handle success response
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Expense added successfully!",
            timer: 3000,
          });
          // Clear the form fields or reset as needed
          this.expense = {
            register: null,
            business_location: null,
            category: null,
            reference_no: "",
            date_added: null,
            expense_for_user: null,
            expense_for_contact: null,
            attach_document: null,
            applicable_tax: null,
            total_amount: null,
            expense_note: "",
            is_refund: false,
            is_recurring: false,
            recurring_interval: null,
            repetitions: null,
          };
        })
        .catch((error) => {
          // Handle error response
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error.message || "Failed to add expense.",
            timer: 5000,
          });
        });
    },
    // Method to handle form submission (if needed)
    handleSubmit() {
      // Add any form submission logic here if needed
    },
    // You can add more methods for handling expenses as needed
  },
};
</script>

<style scoped>
/* Add scoped styles if needed */
</style>
